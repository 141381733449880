// src/data/products.js

const products = [
  {
    id: 1,
    name: 'Causal Shoes',
    price: 2000,
    category: 'Men',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/DVS.png',
    ],
    description: 'Lightweight and breathable running shoes perfect for long-distance runs and daily training.',
    features: ['Breathable mesh upper', 'Responsive cushioning', 'Durable rubber outsole'],
    sizes: [39,40,41,42,43],
    colors: ['Blue', 'Black', 'White'],
    reviews: [
      { id: 1, username: 'Santosh', rating: 4, comment: 'Great shoes, very comfortable!' },
      { id: 2, username: 'Bikash', rating: 5, comment: 'Ekdum ramro.' },
      { id: 3, username: 'Sukesh', rating: 5, comment: 'Good price.' }
    ]
  },
  {
    id: 2,
    name: 'Casual Sneakers',
    price: 2500,
    category: 'Women',
    isNewArrival: false,
    isSale: true,
    images: [
      '/ProductImages/Warrior women.png',
      '/ProductImages/Warrior color.png'
    ],
    description: 'Comfortable and stylish sneakers suitable for everyday wear.',
    features: ['Cushioned insole', 'Flexible rubber outsole'],
    sizes: [39,40,41,42,43],
    colors: ['White', 'Gray', 'Navy'],
    reviews: [
      { id: 1, username: 'Sunita', rating: 5, comment: 'These sneakers are perfect for everyday use. So comfortable!' },
      { id: 2, username: 'Kaplana', rating: 4, comment: 'Love the look and price' }
    ]
  },
  {
    id: 3,
    name: 'Jordon',
    price: 2000,
    category: 'Men',
    isNewArrival: false,
    isSale: false,
    images: [
      '/ProductImages/Jordon.png',
      '/ProductImages/Jordon1.png'
    ],
    description: 'Comfortable causal shoes',
    features: ['Comfortable fit', 'Durable construction'],
    sizes: [39,40,41,42,43],
    colors: ['Black', 'White', 'Blue'],
    reviews: [
      { id: 1, username: 'Bikash', rating: 5, comment: 'These shoes are perfect for casual wear. Very comfortable!' },
      { id: 2, username: 'Shoe Lover', rating: 4, comment: 'Good quality shoes and price.' }
    ]
  },
  {
    id: 4,
    name: 'High Heels',
    price: 1500,
    category: 'Women',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/Sandle.png',
      '/ProductImages/Sandle 2.png'
    ],
    description: 'Elegant high heels for special occasions.',
    features: ['4-inch heel', 'Padded insole', 'Ankle strap'],
    sizes: [39,40,41,42,43],
    colors: ['Black'],
    reviews: [
      { id: 1, username: 'GlamGirl', rating: 5, comment: 'These heels are stunning! Comfortable enough to dance all night.' },
      { id: 2, username: 'Shrinka', rating: 4, comment: 'Beautiful shoes, but they run a bit small.' }
    ]
  },
  {
    id: 5,
    name: 'Warrior Shoes',
    price: 2500,
    category: 'Men',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/Warrior white.png',
      '/ProductImages/Warrior Black.png'
    ],
    description: 'Casual shoes for everyday use.',
    features: ['Cushioned insole', 'Flexible rubber outsole'],
    sizes: [ 39,40,41,42,43],
    colors: ['Black', 'White'],
    reviews: [
      { id: 1, username: 'Dipesh', rating: 5, comment: 'Feels very comfortable with this best price' },
      { id: 2, username: 'Shrinka', rating: 5, comment: 'Unexpectedly good.' }
    ]
  },
  {
    id: 6,
    name: 'Jordon Fashion',
    price: 3500,
    category: 'Men',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/JordonFashion1.png',
      '/ProductImages/JordonFashion2.png',
      '/ProductImages/JordonFashion3.png',
      '/ProductImages/JordonFashion4.png',
    ],
    description: 'Party Jordon Shoes.',
    features: ['Cushioned insole', 'Flexible rubber outsole', 'Attractive looks'],
    sizes: [ 39,40,41,42,43],
    colors: ['Yellow', 'Green','White','Sky Blue'],
    reviews: [
      { id: 1, username: 'Hem', rating: 5, comment: 'Attractive' },
    ]
  },
  {
    id: 7,
    name: 'Jordon Women',
    price: 3500,
    category: 'Women',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/JordonWomen1.png',
      '/ProductImages/JordonWomen2.png',
      '/ProductImages/JordonWomen3.png',
      '/ProductImages/JordonWomen4.png',
    ],
    description: 'Jordon High Class.',
    features: ['Cushioned insole', 'Flexible rubber outsole', 'Attractive looks'],
    sizes: [ 39,40,41,42,43],
    colors: ['White', 'Sky Blue','Gray','Black'],
    reviews: [
    ]
  },
  {
    id: 8,
    name: 'Nike Casual',
    price: 2500,
    category: 'Men',
    isNewArrival: true,
    isSale: false,
    images: [
      '/ProductImages/NikeCasual1.png',
      '/ProductImages/NikeCasual2.png',
      '/ProductImages/NikeCasual3.png',
      '/ProductImages/NikeCasual4.png',
    ],
    description: 'Nike Casual.',
    features: ['Cushioned insole', 'Flexible rubber outsole', 'Attractive looks'],
    sizes: [ 39,40,41,42,43],
    colors: ['Brown'],
    reviews: [
      { id: 1, username: 'NikeLover', rating: 5, comment: 'Great for everyday use' },
    ]
  },
];

export default products;