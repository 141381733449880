// src/components/Favorites.js
import React from 'react';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';
import products from '../Data/Products';
import '../styles/Favorites.css';

function Favorites({ favorites, setFavorites }) {
  const favoriteProducts = products.filter(product => favorites.includes(product.id));

  const removeFavorite = (productId) => {
    setFavorites(prevFavorites => prevFavorites.filter(id => id !== productId));
  };

  return (
    <div className="favorites">
      <h2>My Favorites</h2>
      {favoriteProducts.length === 0 ? (
        <p>You haven't added any favorites yet.</p>
      ) : (
        <div className="favorites-grid">
          {favoriteProducts.map(product => (
            <div key={product.id} className="favorite-item">
              <button 
                className="remove-favorite" 
                onClick={() => removeFavorite(product.id)}
              >
                <X size={20} />
              </button>
              <Link to={`/product/${product.id}`}>
                <img src={product.images[0]} alt={product.name} />
                <h3>{product.name}</h3>
                <p>${product.price.toFixed(2)}</p>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Favorites;