// src/components/About.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Star, DollarSign, Smile, Heart, Award, ThumbsUp } from 'react-feather';
import '../styles/About.css';

function About() {
  return (
    <div className="about-container">
      <div className="about-hero">
        <h1>About Foot Heaven</h1>
        <p>Where Comfort Meets Style, and Quality Meets Affordability</p>
      </div>
      
      <section className="about-intro">
        <h2>Our Story</h2>
        <p>Founded in 2020, Foot Heaven was born out of a passion for providing high-quality, comfortable, and stylish footwear at prices that don't break the bank. We believe that everyone deserves to walk in comfort and confidence, without compromising on style or their budget.</p>
      </section>
      
      <section className="about-features">
        <h2>What Sets Us Apart</h2>
        <div className="features-grid">
          <div className="feature-item">
            <Star className="feature-icon" />
            <h3>Premium Quality</h3>
            <p>We source only the best materials to ensure durability and comfort in every step.</p>
          </div>
          <div className="feature-item">
            <DollarSign className="feature-icon" />
            <h3>Affordable Prices</h3>
            <p>Great shoes shouldn't cost a fortune. We offer competitive prices without compromising on quality.</p>
          </div>
          <div className="feature-item">
            <Smile className="feature-icon" />
            <h3>Friendly Service</h3>
            <p>Our team is dedicated to providing a warm, personalized shopping experience for every customer.</p>
          </div>
          <div className="feature-item">
            <Heart className="feature-icon" />
            <h3>Customer-Centric</h3>
            <p>Your satisfaction is our top priority. We go above and beyond to ensure you're happy with your purchase.</p>
          </div>
          <div className="feature-item">
            <Award className="feature-icon" />
            <h3>Wide Selection</h3>
            <p>From casual to formal, sports to fashion, we have the perfect pair for every occasion.</p>
          </div>
          <div className="feature-item">
            <ThumbsUp className="feature-icon" />
            <h3>Satisfaction Guaranteed</h3>
            <p>We stand behind our products with a hassle-free return policy and excellent after-sales support.</p>
          </div>
        </div>
      </section>
      
      <section className="about-commitment">
        <h2>Our Commitment to You</h2>
        <p>At Foot Heaven, we're not just selling shoes; we're providing a foundation for your comfort and confidence. Whether you're running a marathon, closing a business deal, or enjoying a night out, we're committed to ensuring your feet are happy every step of the way.</p>
        <p>We value the trust our customers place in us and strive to maintain it by consistently delivering on our promises of quality, affordability, and exceptional service.</p>
      </section>
      
      <section className="about-cta">
        <h2>Experience the Foot Heaven Difference</h2>
        <p>Step into comfort, step into style, step into Foot Heaven. Browse our collection today and find your perfect pair!</p>
        <Link to="/">
        <button className="cta-button">Shop Now</button>
      </Link>
      </section>
    </div>
  );
}

export default About;