// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import Header from './components/Header';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Cart from './components/Cart';
import Favorites from './components/Favorites';
import './styles/App.css';
import Checkout from './components/Checkout';
import Footer from './components/Footer';
import Contact from './components/Contact';
import About from './components/About';



function App() {
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [favorites, setFavorites] = useState(() => {
    const savedFavorites = localStorage.getItem('favorites');
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  return (
    <Router>
      <CartProvider>
        <div className="App">
          <Header 
            setCategoryFilter={setCategoryFilter} 
            cartItemsCount={cartItems.length}
            favoritesCount={favorites.length}
          />
          <main>
            <Routes>
              <Route 
                path="/" 
                element={
                  <ProductList 
                    categoryFilter={categoryFilter} 
                    favorites={favorites}
                    setFavorites={setFavorites}
                  />
                } 
              />
              <Route 
                path="/product/:id" 
                element={
                  <ProductDetail 
                    favorites={favorites}
                    setFavorites={setFavorites}
                  />
                } 
              />
              <Route 
                path="/cart" 
                element={<Cart cartItems={cartItems} setCartItems={setCartItems} />} 
              />
              <Route 
                path="/favorites" 
                element={<Favorites favorites={favorites} setFavorites={setFavorites} />} 
              />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />


            </Routes>
            
          </main>
          <Footer />
        </div>
      </CartProvider>
    </Router>
  );
}

export default App;