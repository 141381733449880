// src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingCart, Heart, Mail } from 'react-feather';
import { useCart } from '../context/CartContext';
import '../styles/Header.css';

function Header({ setCategoryFilter, favoritesCount }) {
  const navigate = useNavigate();
  const { cartItems } = useCart();

  const handleCategoryClick = (category) => {
    setCategoryFilter(category);
    navigate('/');
  };

  const handleLogoClick = () => {
    setCategoryFilter('All');
    navigate('/');
  };

  const cartItemsCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  return (
    <header className="main-header">
      <div className="header-left" >
        <Link to="/" className="logo" onClick={handleLogoClick}  >
          <img src="/foot-heaven-high-resolution-logo.png" alt="ShoeStore Logo" style={{ height:100}} /> 
        </Link>
        {/* <span style={{ textAlign: 'center'}}>Foot Heaven</span> */}
      </div>
      <nav className="header-center">
        <button onClick={() => handleCategoryClick('Men')}>Men</button>
        <button onClick={() => handleCategoryClick('Women')}>Women</button>
        <button onClick={() => handleCategoryClick('Sales')}>Sales</button>
        <button onClick={() => handleCategoryClick('New Arrivals')}>New Arrivals</button>
      </nav>
      <div className="header-right">
      <Link to="/contact" className="icon-button"> {/* Add this line */}
          <Mail />
        </Link>
        <Link to="/favorites" className="icon-button">
          <Heart />
          {favoritesCount > 0 && <span className="badge">{favoritesCount}</span>}
        </Link>
        <Link to="/cart" className="icon-button">
          <ShoppingCart />
          {cartItemsCount > 0 && <span className="badge">{cartItemsCount}</span>}
        </Link>
        
      </div>
    </header>
  );
}

export default Header;