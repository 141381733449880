// src/components/Checkout.js
import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../styles/Checkout.css';


function Checkout() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    zipCode: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const [errors, setErrors] = useState({});

  const { cartItems, clearCart } = useCart();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the error for this field when the user starts typing
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (!formData.city.trim()) newErrors.city = 'City is required';
    if (!formData.zipCode.trim()) newErrors.zipCode = 'Zip Code is required';
    else if (!/^\d{5}(-\d{4})?$/.test(formData.zipCode)) newErrors.zipCode = 'Invalid Zip Code';
    if (!formData.cardNumber.trim()) newErrors.cardNumber = 'Card Number is required';
    else if (!/^\d{16}$/.test(formData.cardNumber.replace(/\s/g, ''))) newErrors.cardNumber = 'Invalid Card Number';
    if (!formData.expiryDate.trim()) newErrors.expiryDate = 'Expiry Date is required';
    else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiryDate)) newErrors.expiryDate = 'Invalid Expiry Date (MM/YY)';
    if (!formData.cvv.trim()) newErrors.cvv = 'CVV is required';
    else if (!/^\d{3,4}$/.test(formData.cvv)) newErrors.cvv = 'Invalid CVV';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Here you would typically send the order to a server
      console.log('Order submitted:', { items: cartItems, customerInfo: formData });
      clearCart();
      navigate('/confirmation');
    }
  };

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="checkout">
      <header className="checkout-header">
        <Link to="/contact" className="contact-button">Contact the Seller</Link>
      </header>
      <h2>Checkout</h2>
      <form onSubmit={handleSubmit}>
        <h3>Shipping Information</h3>
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          className={errors.name ? 'error' : ''}
        />
        {errors.name && <span className="error-message">{errors.name}</span>}
        
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className={errors.email ? 'error' : ''}
        />
        {errors.email && <span className="error-message">{errors.email}</span>}
        
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
          className={errors.address ? 'error' : ''}
        />
        {errors.address && <span className="error-message">{errors.address}</span>}
        
        <input
          type="text"
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
          className={errors.city ? 'error' : ''}
        />
        {errors.city && <span className="error-message">{errors.city}</span>}
        
        <input
          type="text"
          name="zipCode"
          placeholder="Zip Code"
          value={formData.zipCode}
          onChange={handleChange}
          className={errors.zipCode ? 'error' : ''}
        />
        {errors.zipCode && <span className="error-message">{errors.zipCode}</span>}

        <h3>Payment Information</h3>
        <input
          type="text"
          name="cardNumber"
          placeholder="Card Number"
          value={formData.cardNumber}
          onChange={handleChange}
          className={errors.cardNumber ? 'error' : ''}
        />
        {errors.cardNumber && <span className="error-message">{errors.cardNumber}</span>}
        
        <input
          type="text"
          name="expiryDate"
          placeholder="MM/YY"
          value={formData.expiryDate}
          onChange={handleChange}
          className={errors.expiryDate ? 'error' : ''}
        />
        {errors.expiryDate && <span className="error-message">{errors.expiryDate}</span>}
        
        <input
          type="text"
          name="cvv"
          placeholder="CVV"
          value={formData.cvv}
          onChange={handleChange}
          className={errors.cvv ? 'error' : ''}
        />
        {errors.cvv && <span className="error-message">{errors.cvv}</span>}

        <h3>Order Summary</h3>
        <ul>
          {cartItems.map(item => (
            <li key={item.id}>{item.name} - Rs.{item.price.toFixed(2)} x {item.quantity}</li>
          ))}
        </ul>
        <p><strong>Total: Rs.{totalPrice.toFixed(2)}</strong></p>

        <button type="submit">Place Order</button>
      </form>
    </div>
  );
}

export default Checkout;