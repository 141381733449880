// src/components/Contact.js
import React, { useState, useEffect } from 'react';
import { Mail, Phone, MapPin } from 'react-feather';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';
import { Facebook, Instagram } from 'react-feather';


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    emailjs.init("NxLDbRnVbLgqUs861"); // Replace with your actual public key
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    emailjs.send(
      "service_3yk4uqj", // Replace with your EmailJS service ID
      "template_dm4l6r3", // Replace with your EmailJS template ID
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        to_email: 'nepalfootheaven@gmail.com'
      }
    ).then((result) => {
      console.log(result.text);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
    }, (error) => {
      console.log(error.text);
      setSubmitStatus('error');
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p>We'd love to hear from you. Here's how you can reach us...</p>
          <div className="contact-details">
            <div className="contact-item">
              <Mail className="contact-icon" />
              <p>nepalfootheaven@gmail.com</p>
            </div>
            <div className="contact-item">
              <Phone className="contact-icon" />
              <p>(986) 010-9346</p>
            </div>
            <div className="contact-item">
              <MapPin className="contact-icon" />
              <p>New Baneshwor, Kathmandu, Nepal</p>
            </div>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61565651780946" target="_blank" rel="noopener noreferrer"><Facebook /></a>
            <a href="https://www.instagram.com/nepalfootheaven/?igsh=MWN4Yms5aGR1OG5yNw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer"><Instagram /></a>
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>Send Us a Message</h2>
          {submitStatus === 'success' && (
            <div className="success-message">
              Thank you for your message! We will get back to you soon.
            </div>
          )}
          {submitStatus === 'error' && (
            <div className="error-message">
              Oops! Something went wrong. Please try again later.
            </div>
          )}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;