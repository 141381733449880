// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from 'react-feather';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: nepalfootheaven@gmail.com</p>
          <p>Phone: (986) 010-9346</p>
          <p>Address: New Baneshwor, Kathmandu, Nepal</p>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61565651780946" target="_blank" rel="noopener noreferrer"><Facebook /></a>
            <a href="https://www.instagram.com/nepalfootheaven/?igsh=MWN4Yms5aGR1OG5yNw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer"><Instagram /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Foot Heaven. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;