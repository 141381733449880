// src/components/Cart.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../styles/Cart.css';

function Cart() {
  const { cartItems, removeFromCart } = useCart();
  const navigate = useNavigate();

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div className="cart">
      <h2>Your Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          <ul className="cart-items">
            {cartItems.map(item => (
              <li key={item.id} className="cart-item">
                <span>{item.name}</span>
                <span>Rs.{item.price.toFixed(2)} x {item.quantity}</span>
                <i onClick={() => removeFromCart(item.id)} class="fa fa-remove" style={{fontSize:'24px',color:'red'}}></i>
              </li>
            ))}
          </ul>
          <div className="cart-total">
            <strong>Total: Rs.{totalPrice.toFixed(2)}</strong>
          </div>
          <button className="checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
        </>
      )}
      <Link to="/" className="continue-shopping">Continue Shopping</Link>
    </div>
  );
}

export default Cart;