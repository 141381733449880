// src/components/ProductList.js
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart } from 'react-feather';
import products from '../Data/Products';
import '../styles/ProductList.css';

function ProductList({ categoryFilter, favorites, setFavorites }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const navigate = useNavigate();

  const filteredAndSortedProducts = useMemo(() => {
    let result = products.filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter === 'All' || 
       (categoryFilter === 'Men' && product.category === 'Men') ||
       (categoryFilter === 'Women' && product.category === 'Women') ||
       (categoryFilter === 'Sales' && product.isSale) ||
       (categoryFilter === 'New Arrivals' && product.isNewArrival))
    );

    switch (sortOption) {
      case 'priceLowToHigh':
        result.sort((a, b) => a.price - b.price);
        break;
      case 'priceHighToLow':
        result.sort((a, b) => b.price - a.price);
        break;
      case 'nameAZ':
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'nameZA':
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        break;
    }

    return result;
  }, [searchTerm, categoryFilter, sortOption]);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleFavoriteClick = (e, productId) => {
    e.stopPropagation();
    setFavorites(prevFavorites => {
      if (prevFavorites.includes(productId)) {
        return prevFavorites.filter(id => id !== productId);
      } else {
        return [...prevFavorites, productId];
      }
    });
  };

  return (
    <div className="product-list">
      <div className="filters">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="sort-select"
        >
          <option value="default">Default Sorting</option>
          <option value="priceLowToHigh">Price: Low to High</option>
          <option value="priceHighToLow">Price: High to Low</option>
          <option value="nameAZ">Name: A to Z</option>
          <option value="nameZA">Name: Z to A</option>
        </select>
      </div>
      <div className="product-grid">
        {filteredAndSortedProducts.map(product => (
          <div key={product.id} className="product-card" onClick={() => handleProductClick(product.id)}>
            <div className="product-image-container">
              <img src={product.images[0]} alt={product.name} className="product-image" />
              <button 
                className={`favorite-button ${favorites.includes(product.id) ? 'favorited' : ''}`}
                onClick={(e) => handleFavoriteClick(e, product.id)}
              >
                <Heart size={20} />
              </button>
            </div>
            <div className="product-info">
              <h3 className="product-name">{product.name}</h3>
              <p className="product-category">{product.category}</p>
              <p className="product-price">Rs.{product.price.toFixed(2)}</p>
              <p className="product-colors">{product.colors.length} colors • {product.isNewArrival ? 'New Arrival' : (product.isSale ? 'On Sale' : '')}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductList;