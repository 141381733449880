// src/components/ProductDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { Heart } from 'react-feather';
import products from '../Data/Products';
import '../styles/ProductDetail.css';

function ProductDetail({ favorites, setFavorites }) {
  const { id } = useParams();
  const product = products.find(p => p.id === parseInt(id));
  const { cartItems, addToCart, updateQuantity, removeFromCart } = useCart();
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const cartItem = cartItems.find(item => item.id === product.id);
    setQuantity(cartItem ? cartItem.quantity : 0);
  }, [cartItems, product.id]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleAddToCart = () => {
    if (!selectedSize || !selectedColor) {
      alert('Please select a size and color');
      return;
    }
    addToCart({ ...product, size: selectedSize, color: selectedColor });
    setQuantity(1);
  };

  const handleUpdateQuantity = (newQuantity) => {
    if (newQuantity === 0) {
      removeFromCart(product.id);
    } else {
      updateQuantity(product.id, newQuantity);
    }
    setQuantity(newQuantity);
  };

  const toggleFavorite = () => {
    setFavorites(prevFavorites => {
      if (prevFavorites.includes(product.id)) {
        return prevFavorites.filter(id => id !== product.id);
      } else {
        return [...prevFavorites, product.id];
      }
    });
  };

  return (
    <div className="product-detail-container">
      <div className="product-detail">
        <div className="product-image-gallery">
          <div className="main-image-container">
            <img 
              src={product.images[currentImageIndex]} 
              alt={product.name} 
              className="main-image" 
            />
            <button className="favorite-button" onClick={toggleFavorite}>
              <Heart 
                fill={favorites.includes(product.id) ? "red" : "none"} 
                color={favorites.includes(product.id) ? "red" : "white"}
              />
            </button>
          </div>
          <div className="thumbnail-container">
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.name} view ${index + 1}`}
                className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </div>
        </div>
        <div className="product-info">
          <h2>{product.name}</h2>
          <p className="price">Rs.{product.price.toFixed(2)}</p>
          <p>{product.description}</p>
          <div className="product-options">
            <select 
              value={selectedSize} 
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              <option value="">Select Size</option>
              {product.sizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
            <select 
              value={selectedColor} 
              onChange={(e) => setSelectedColor(e.target.value)}
            >
              <option value="">Select Color</option>
              {product.colors.map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </select>
          </div>
          <div className="cart-actions">
            {quantity === 0 ? (
              <button className="add-to-cart-button" onClick={handleAddToCart}>
                Add to Cart
              </button>
            ) : (
              <div className="quantity-control">
                <button onClick={() => handleUpdateQuantity(quantity - 1)}>-</button>
                <span>{quantity}</span>
                <button onClick={() => handleUpdateQuantity(quantity + 1)}>+</button>
              </div>
            )}
          </div>
          <div className="product-features">
            <h3>Features:</h3>
            <ul>
              {product.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="reviews-section">
            <h3>Customer Reviews</h3>
            {product.reviews.map(review => (
              <div key={review.id} className="review">
                <div className="review-header">
                  <span>{review.username}</span>
                  <span className="review-rating">
                    {"★".repeat(review.rating)}{"☆".repeat(5 - review.rating)}
                  </span>
                </div>
                <p className="review-comment">{review.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;